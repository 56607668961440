<template>
  <div v-loading="loading" class="add-requet-page">
    <div class="title-type">
      <span>{{ this.$store.state.design.formName }}</span>
    </div>
    <!--    <nav-bar fixed :title="`发起-${form.formName}`" left-text="取消" right-text="提交" left-arrow @click-left="back" @click-right="submitForm"/>-->
    <tabs v-model="active" :lazy-render="false" animated swipeable sticky>
      <tab title="审批表单"></tab>
      <tab title="审批流程"></tab>
    </tabs>
    <div style="padding-bottom: 7rem">
      <div v-if="userDepts.length > 1" style="margin-bottom: 10px">
        本次发起部门：
        <radio-group v-model="userDeptId" direction="horizontal">
          <radio v-for="dept in userDepts" :key="dept.id" :name="dept.id">{{
            dept.name
          }}</radio>
        </radio-group>
      </div>
      <div class="tick-info-and-form" v-show="active === 0">
        <form-render
          v-if="!loading"
          mode="MOBILE"
          class="process-form"
          ref="form"
          :forms="forms"
          v-model="formData"
          :config="form.formConfig"
        />
        <!-- 调拨单明细,多组的形式 -->

        <div class="info-outer">
          <!-- ```` {{ selectType }}```` -->
          <div class="info-title-outer">
            <span>{{ selectType | filterTiTle }}</span>
            <span class="clear" @click="clearAll">清空</span>
          </div>
          <!-- 不同用户不用组 -->
          <template v-for="(item, index) in carList">
            <div class="item" :key="index">
              <!-- 销售礼券申请与免费礼券申请的时候才显示用户信息 -->
              <div
                class="user-group-head"
                v-show="selectType == '2' || selectType == '3'"
              >
                <div class="user-title" @click="editUserHandle(item, index)">
                  <span>客人{{ index + 1 }}：</span>

                  <span class="user-info" v-if="item.userName || item.userPhone"
                    >{{ item.userName }}{{ item.userPhone }}</span
                  >
                  <span v-else class="not-user-err"
                    >请添加用户信息（姓名、手机号）</span
                  >
                  <van-icon name="edit" color="#FFF" size="1.2rem" />
                </div>
                <!-- <div
                  class="err"
                  v-if="item.attributeType == 2 && item.userPhone == ''"
                >
                  <span>电子券手机号必填</span>
                </div> -->
                <div class="delet-user" @click.stop="clearItem(index)">
                  <van-icon name="close" color="#ccc" size="1.5rem" />
                </div>
              </div>

              <div class="add-info-btn" @click.stop="addInfoBtn(index)">
                +添加券品
              </div>
              <div class="info-list">
                <template v-for="(item2, index2) in item.carListbyUser">
                  <div class="con-item" :key="index2">
                    <!-- <span class="type">单体券</span> -->
                    <div class="item-title">
                      <span
                        @click.stop="
                          goEditTick(JSON.stringify(item2), index, index2)
                        "
                        >{{ item2.brandName }}</span
                      >
                      <van-icon
                        name="delete"
                        color="#333"
                        size="1.7rem"
                        @click="deleteItemForGroup(index, index2)"
                      />
                    </div>

                    <!-- 有效期券介质数目一行前4类型都要 -->
                    <div
                      class="item-line"
                      v-if="
                        selectType == '1' ||
                        selectType == '2' ||
                        selectType == '3' ||
                        selectType == '4'
                      "
                    >
                      <template v-if="item2.validTimeType == '1'">
                        <span class="one" v-if="selectType != '4'"
                          >有效期：以套明细为准</span
                        >
                      </template>
                      <template v-else>
                        <span class="one" v-if="selectType != '4'"
                          >有效期：{{ item2.timeEnd
                          }}{{ item2.timeType == "0" ? "天" : "" }}</span
                        >
                      </template>

                      <span class="two" v-if="selectType != '4'"
                        >券介质：{{
                          item2.attributeType == "1"
                            ? "纸质"
                            : item2.attributeType == "2"
                            ? "电子"
                            : "虚拟券"
                        }}</span
                      >
                      <span class="one" v-if="selectType == '4'"
                        >面额：{{ item2.brandPrice }}</span
                      >
                      <van-stepper
                        step="1"
                        button-size="24"
                        integer
                        :name="{ groupIndex: index, index2: index2 }"
                        class="three"
                        v-model.number="item2.shenqingCount"
                        @change="stepChange"
                      />
                    </div>
                    <!-- 单价那一行只有2要 -->
                    <div class="item-line-option" v-if="selectType == '2'">
                      <span class="price">面额：¥{{ item2.brandPrice }}</span>

                      <div class="edit-price">
                        <span class="dis-price"
                          >(折扣{{
                            ((item2.sellPrice / item2.brandPrice) * 100)
                              | priceFixed
                          }}%)
                        </span>
                        <span
                          :class="{
                            'dj-price': true,
                            'price-err':
                              !item2.sellPrice || item2.sellPrice < 0,
                          }"
                          >单价 ¥</span
                        >
                        <el-input
                          v-model="item2.sellPrice"
                          placeholder="请输入单价"
                          clearable
                          @input="inputChange(item2.sellPrice, index, index2)"
                          type="number"
                        ></el-input>
                      </div>
                    </div>

                    <!-- 折扣申请那三行折扣、单价、总价 -->
                    <template v-else-if="selectType == '4'">
                      <div class="item-line-option more-option">
                        <span class="price">申请折扣</span>
                        <div class="edit-price">
                          <el-input
                            v-model="item2.discount"
                            placeholder="请输入折扣"
                            clearable
                            @input="
                              inputChangeDiscount(item2.discount, index, index2)
                            "
                            type="number"
                          ></el-input>
                          <span class="dj-price">%</span>
                        </div>
                      </div>
                      <div class="item-line-option more-option">
                        <span class="price">申请单价</span>
                        <div class="edit-price">
                          <el-input
                            v-model="item2.discountedPrice"
                            placeholder="请输入申请单价"
                            clearable
                            @input="
                              inputDiscountPrice(
                                item2.discountedPrice,
                                index,
                                index2
                              )
                            "
                            type="number"
                          ></el-input>
                          <span class="dj-price">元</span>
                        </div>
                      </div>
                      <div class="item-line-option more-option">
                        <span class="price">申请总价</span>
                        <div class="edit-price">
                          <span>{{ item2.discountedTotalPrice }}</span>
                          <span class="dj-price">元</span>
                        </div>
                      </div>
                    </template>
                    <!-- 延期申请 -->
                    <template v-else-if="selectType == '5'">
                      <div class="delay-detail-line">
                        <div class="d-line-item flex-max">
                          <span class="title">券号：</span>
                          <span class="txt">{{ item2.goodsBn }}</span>
                        </div>
                      </div>
                      <div class="delay-detail-line">
                        <div class="d-line-item flex-max">
                          <span class="title">券类：</span>
                          <span class="txt">{{ item2.categoryName }}</span>
                        </div>
                        <div class="d-line-item">
                          <span class="title">券介质：</span>
                          <span class="txt"
                            >{{
                              item2.attributeType == "1"
                                ? "纸质"
                                : item2.attributeType == "2"
                                ? "电子"
                                : "虚拟券"
                            }}券</span
                          >
                        </div>
                      </div>
                      <div class="delay-detail-line">
                        <div class="d-line-item flex-max">
                          <span class="title">有效期：</span>
                          <span class="txt">{{ item2.endTime }}</span>
                        </div>
                        <div class="d-line-item">
                          <span class="title">是否已过期：</span>
                          <span class="txt">{{
                            item2.isEffective == "1" ? "是" : "否"
                          }}</span>
                        </div>
                      </div>
                      <div class="delay-detail-line">
                        <div class="d-line-item flex-max">
                          <span class="title">券用途：</span>
                          <span class="txt">{{
                            item2.useType == "1"
                              ? "销售"
                              : item2.useType == "2"
                              ? "免费"
                              : "工具"
                          }}</span>
                        </div>
                        <div class="d-line-item">
                          <span class="title">状态：</span>
                          <span class="txt">{{
                            item2.status | typeFilter
                          }}</span>
                        </div>
                      </div>
                      <div class="item-line-option more-option">
                        <span class="price">延期天数</span>
                        <div class="edit-price">
                          <el-input
                            v-model="item2.days"
                            placeholder="请输入延期天数"
                            clearable
                            @input="inputDelayDays(item2.days, index, index2)"
                          ></el-input>
                          <span class="dj-price">天</span>
                        </div>
                      </div>
                      <div
                        class="day-err"
                        v-if="item2.days == '' || item2.days == 0"
                      >
                        <span>延期天数至少是1</span>
                      </div>
                      <div class="item-line-option more-option">
                        <span class="price">延期至</span>
                        <div
                          class="edit-price"
                          @click="delayTodate(item2.endTime, index, index2)"
                        >
                          <span class="date-edit-delay">{{
                            item2.optTime
                          }}</span>
                          <van-icon
                            name="arrow"
                            color="#006c50"
                            size="1.2rem"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>

      <process-render
        @render-ok="submitLoading = false"
        ref="process"
        v-show="active === 1"
        v-if="!loading && userDeptId"
        :process-def-id="form.processDefId"
        :dept-id="userDeptId"
        v-model="processUsers"
        :forms="forms"
        :formData="formData"
        :process="process"
        style="padding: 10px"
      />
    </div>
    <div class="btns">
      <!-- 销售礼券申请与免费礼券申请的时候才显示添加用户 -->
      <template v-if="selectType == '2' || selectType == '3'">
        <v-button
          class="add-btn"
          round
          type="info"
          @click="addUser"
          color="#EEB100"
          >添加用户</v-button
        >
      </template>

      <v-button
        class="submit-btn"
        :loading="submitLoading"
        round
        type="info"
        @click="submit"
        color="#006C50"
        >提交审批</v-button
      >
    </div>
    <!-- 修改用户名手机号 -->
    <van-popup
      v-model="showEditUser"
      closeable
      round
      position="bottom"
      :style="{ 'max-height': '90%' }"
    >
      <div class="popup-sku-con-outer delay-outer">
        <div class="pop-title-con">
          <span>编辑客人信息</span>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">用户名：</span>
          <div class="edit-input">
            <el-input
              v-model="editUserName"
              placeholder="请填写用户名"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">手机号：</span>
          <div class="edit-input">
            <el-input
              v-model="editUserPhone"
              placeholder="请填写手机号"
              clearable
              type="number"
            ></el-input>
          </div>
        </div>

        <div class="pop-btns pop-btns-edit-user">
          <van-button type="primary" round @click="submitEdit">确认</van-button>
        </div>
      </div>
    </van-popup>
    <van-calendar
      v-model="showPopDelay"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="btnPopDelayDate"
      color="#006C50"
    />
  </div>
</template>

<script>
import { Radio, RadioGroup, NavBar, Tabs, Tab, Button, Toast } from "vant";
import ProcessRender from "../process/ProcessRender";
import FormRender from "@/views/common/form/FormRender";
import FormDesignRender from "@/views/admin/layout/form/FormDesignRender";
import { getModelById } from "@/api/modelGroup";
import { getInstanceFormData } from "@/api/processTask";
import { getUserDepts } from "@/api/org";
import { startProcess } from "@/api/process";
import moment from "moment";
import uniWebview from "@dcloudio/uni-webview-js";
const VButton = Button;
export default {
  name: "MbInitiateProcess",
  components: {
    FormDesignRender,
    FormRender,
    ProcessRender,
    VButton,
    Radio,
    RadioGroup,
    NavBar,
    Tabs,
    Tab,
  },
  data() {
    return {
      active: 0,
      showPopDelay: false,
      loading: false,
      submitLoading: false,
      editUserName: "",
      editUserPhone: "",
      userDeptId: "",
      curobjIndex: "",
      minDate: new Date(0),
      maxDate: moment().add(20, "years").toDate(),
      showEditUser: false,
      formData: {},
      userDepts: [],
      optionObj: {},
      // carList: [],
      selectType: "", //1.调拨单申请2.销售礼券申请 ，3免费礼券申请,4销售折扣申请,5礼券延期申请,空串表示非我们定义的模板
      processUsers: {},
      form: {
        formId: "",
        formName: "",
        logo: {},
        formItems: [],
        process: {},
        remark: "",
      },
    };
  },
  filters: {
    typeFilter(mType) {
      if (mType == "1") {
        return "在库";
      } else if (mType == "2") {
        return "调拨";
      } else if (mType == "3") {
        return "销售";
      } else if (mType == "4") {
        return "发放";
      } else if (mType == "5") {
        return "转赠";
      } else if (mType == "6") {
        return "核销";
      } else if (mType == "7") {
        return "强制核销";
      } else if (mType == "8") {
        return "确认收入";
      } else if (mType == "9") {
        return "作废";
      } else {
        return "";
      }
    },
    filterTiTle(value) {
      let title = "";
      if (value == "1") {
        title = "调拨明细";
      } else if (value == "2") {
        title = "销售明细";
      } else {
        title = "券明细";
      }
      return title;
    },
    priceFixed(value) {
      return parseFloat(value).toFixed(2);
    },
  },
  mounted() {
    // console.log(
    //   "mountedmountedmountedmountedmountedmountedmountedmountedmountedmountedmounted"
    // );
    // this.sendTitle()
    if (this.$isNotEmpty(this.$route.query.code)) {
      this.getUserDept();
      this.loadFormInfo(this.$route.query.code);
      //console.log(this.$route.query.modeNo+"每个item带的类型")
      this.selectType = this.$route.query.modeNo;
      //  this.carList = [...this.$store.state.sendTickCarList];
      //1.调拨单申请2.销售礼券申请 ，3免费礼券申请,4销售折扣申请,5礼券延期申请,空串表示非我们定义的模板
    } else {
      Toast.fail("参数缺失");
    }
  },
  activated() {
    //console.log("mbinitiate````activated`````````");
  },
  deactivated() {
    //console.log("mbinitiate````deactivated`````````");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log("mbinitiate````beforeRouteEnter```" + from.path);
      if (from.path == "/workspace/forms" || from.path == "/workspace/submit") {
        // console.log("工作台进来的" + from.path);
        if (vm.$isNotEmpty(vm.$route.query.code)) {
          vm.getUserDept();
          vm.loadFormInfo(vm.$route.query.code);
          //console.log(this.$route.query.modeNo+"每个item带的类型")
          vm.selectType = vm.$route.query.modeNo;

          //  this.carList = [...this.$store.state.sendTickCarList];
          //1.调拨单申请2.销售礼券申请 ，3免费礼券申请,4销售折扣申请,5礼券延期申请,空串表示非我们定义的模板
        } else {
          Toast.fail("参数缺失");
        }
      }
    });

    // console.log("to===", to);
    // console.log("from ===", from);
    // console.log("next ===", next);
    //next();
  },
  beforeRouteLeave(to, from, next) {
    console.log("离开了啊mbinitiate```beforeRouteLeave" + to.path);
    if (to.path == "/workspace/forms" || to.path == "/workspace/submit") {
      console.log("离开去工作台了11或者我的提交页面清缓存");
      this.$vnode.parent.componentInstance.cache = {};
      this.$vnode.parent.componentInstance.keys = [];
    }
    next();

    // console.log("to===", to);
    // console.log("from ===", from);
    // console.log("next ===", next);
    //next();
  },
  computed: {
    carList() {
      return [...this.$store.state.sendTickCarList];
    },
    curTickList() {
      return this.$store.state.sendTickCarList;
    },
    forms() {
      return this.$store.state.design.formItems;
    },
    process() {
      return this.$store.state.design.process;
    },
  },
  methods: {
    //  sendTitle(){
    //    uniWebview.postMessage({
    //    data: { name:"协同-发起审批",flag:"title"}
    //    })
    //},
    submitEdit() {
      //   this.editUserName=item.userName
      // this.editUserPhone=item.userPhone
      // this.showEditUser=true
      let tepCarList = [...this.$store.state.sendTickCarList];
      tepCarList[this.curobjIndex].userName = this.editUserName;
      tepCarList[this.curobjIndex].userPhone = this.editUserPhone;
      tepCarList[this.curobjIndex].carListbyUser = tepCarList[
        this.curobjIndex
      ].carListbyUser.map((item) => {
        return {
          ...item,
          userName: this.editUserName,
          userPhone: this.editUserPhone,
        };
      });
      this.showEditUser = false;
      this.$store.commit("setTickCarList", tepCarList);
    },
    editUserHandle(item, index) {
      //carList
      //    editUserName:"",
      // editUserPhone:"",
      // showEditUser:false,
      this.curobjIndex = index;
      this.editUserName = item.userName;
      this.editUserPhone = item.userPhone;
      this.showEditUser = true;
    },
    clearItem(index) {
      ///购物车删除一个组
      let tepCarList = [...this.$store.state.sendTickCarList];
      tepCarList.splice(index, 1);
      if (tepCarList.length < 1) {
        //购物车清没了，添加一个初始的那个
        tepCarList = [
          {
            userName: "",
            userPhone: "",
            carListbyUser: [],
          },
        ];
      }
      this.$store.commit("setTickCarList", tepCarList);
    },
    deleteItemForGroup(groupIndex, index) {
      const newList = [...this.$store.state.sendTickCarList];
      newList[groupIndex].carListbyUser.splice(index, 1);
      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
    },
    clearAll() {
      //清空全部购物车，注意要留一个初始化的。
      this.$store.commit("clearTickCartList");
      let carList = [
        {
          userName: "",
          userPhone: "",
          carListbyUser: [],
        },
      ];
      this.$store.commit("setTickCarList", carList);
    },
    goEditTick(item, groupIndex, subIndex) {
      let itemParam = JSON.parse(item);
      let ParamObj = {
        ...itemParam,
        groupIndex: groupIndex,
        subIndex: subIndex,
        carSelectType: this.selectType,
        selectType: this.selectType,
      };
      this.$router.push({
        path: "/tickRequest",
        query: {
          paramObj: JSON.stringify(ParamObj),
          selectType: this.selectType,
        },
      });
      // this.$router.push({
      //   path: "/tickRequestDetails",
      //   query: {
      //     paramObj: item,
      //     isEdit: 0,
      //   },
      // });
    },
    inputChange(price, groupIndex, index) {
      // console.log("输入了" + price);
      // let dellPrice = "";
      // if (!price || price < 0) {
      //   console.log("价格是空的改成0···" + price);
      //   dellPrice = 0;
      // } else {
      //   dellPrice = price;
      // }
      console.log("最后用的价格···" + price);
      const newList = [...this.$store.state.sendTickCarList];
      newList[groupIndex].carListbyUser[index].sellPrice = price;
      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
    },
    //修改延期至
    delayTodate(endTime, groupIndex, index) {
      this.optionObj = {
        groupIndex: groupIndex,
        index: index,
      };
      //  minDate: new Date(0),
      //   console.log("元日期"+new Date(0)+"endTime"+endTime+"算"+new Date(moment(endTime)))
      this.minDate = endTime ? new Date(moment(endTime)) : new Date(0);
      //   this.maxDate=
      this.showPopDelay = true;
    },
    //确定选择了延期日期
    btnPopDelayDate(date) {
      let groupIndex = this.optionObj.groupIndex;
      let index = this.optionObj.index;
      //  this.delayToTime=date
      this.showPopDelay = false;
      //  console.log(moment(date).format("YYYY-MM-DD"));

      const newList = [...this.$store.state.sendTickCarList];

      let curEndTime = newList[groupIndex].carListbyUser[index].endTime; //有效期
      // console.log(
      //   "选择的日期" +
      //     moment(date).format("YYYY-MM-DD") +
      //     "原来有效期" +
      //     moment(curEndTime) +
      //     "相差" +
      //     moment(date).diff(moment(curEndTime), "day")
      // );
      //   // 更新 Vuex 数据
      newList[groupIndex].carListbyUser[index].optTime =
        moment(date).format("YYYY-MM-DD");
      newList[groupIndex].carListbyUser[index].days = moment(date).diff(
        moment(curEndTime),
        "day"
      );
      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
      //this.$store.commit("setTickCarList", newList);
      //this.minDate=endTime?new Date(moment(endTime)):new Date(0)
      // this.delayToTime = moment(date).format("YYYY-MM-DD");
    },
    //修改延期天数
    inputDelayDays(dayNums, groupIndex, index) {
      // alert(dayNums);
      let regDayNum = dayNums.replace(/[^0-9]/g, "");
      if (regDayNum.indexOf(0) == 0) {
        regDayNum = "";
      } else if (dayNums != "" && regDayNum == "") {
        regDayNum = "";
      }
      this.carList[groupIndex].carListbyUser[index].days = regDayNum;
      const newList = [...this.$store.state.sendTickCarList];
      let curEndTime = newList[groupIndex].carListbyUser[index].endTime; //有效期
      newList[groupIndex].carListbyUser[index].days = regDayNum;
      newList[groupIndex].carListbyUser[index].optTime = moment(curEndTime)
        .add(regDayNum, "days")
        .format("YYYY-MM-DD");
      // console.log(
      //   "有效期" +
      //     curEndTime +
      //     "延期天数" +
      //     regDayNum +
      //     "```" +
      //     dayNums +
      //     "延期至" +
      //     moment(curEndTime).add(regDayNum, "days").format("YYYY-MM-DD")
      // );
      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
    },
    //修改销售折扣
    inputChangeDiscount(discount, groupIndex, index) {
      // 检查输入值是否为整数并且在0到100之间
      // if (discount && !/^(?:0|[1-9]\d?|100)$/.test(discount)) {
      //   // 如果不是，则设置为上一个有效值或者0
      //   console.log("不合格")
      //  this.carList[groupIndex].carListbyUser[index].discount = "";
      // } else {
      //   // 如果是，则更新值
      //    console.log("合格")
      //  this.carList[groupIndex].carListbyUser[index].discount= discount;
      // }
      //  console.log("合格"+this.carList[groupIndex].carListbyUser[index].discount)

      //console.log("改折扣了" + discount);
      const newList = [...this.$store.state.sendTickCarList];
      // newList[groupIndex].carListbyUser[index].discount = discount;
      let dPrice2 =
        (discount / 100) * newList[groupIndex].carListbyUser[index].brandPrice;
      let dPrice = parseFloat(dPrice2).toFixed(2);
      let shenqingCount =
        newList[groupIndex].carListbyUser[index].shenqingCount;
      let totalPrice = shenqingCount * dPrice;
      // let shenqingCount = parseFloat(shenqingCount2).toFixed(2);
      // console.log(
      //   "折扣" +
      //     discount / 100 +
      //     "面额" +
      //     newList[groupIndex].carListbyUser[index].brandPrice +
      //     "计算的申请单价" +
      //     dPrice +
      //     "申请数量" +
      //     shenqingCount +
      //     "申请总价" +
      //     shenqingCount * dPrice
      // );
      newList[groupIndex].carListbyUser[index].discountedPrice = dPrice;
      newList[groupIndex].carListbyUser[index].discount = discount;
      newList[groupIndex].carListbyUser[index].discountedTotalPrice =
        parseFloat(totalPrice).toFixed(2);
      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
    },
    inputDiscountPrice(discountPrice, groupIndex, index) {
      //console.log("折扣价格改了" + discountPrice);
      const newList = [...this.$store.state.sendTickCarList];
      let brandPrice = newList[groupIndex].carListbyUser[index].brandPrice;
      let shenqingCount =
        newList[groupIndex].carListbyUser[index].shenqingCount;
      let sumDiscount = (discountPrice / brandPrice) * 100;
      //  newList[groupIndex].carListbyUser[index].discount =sumDiscount.toFixed(2);
      newList[groupIndex].carListbyUser[index].discount =
        parseFloat(sumDiscount).toFixed(2);
      newList[groupIndex].carListbyUser[index].discountedPrice = discountPrice;
      let tPriceAll = shenqingCount * discountPrice;
      newList[groupIndex].carListbyUser[index].discountedTotalPrice =
        parseFloat(tPriceAll).toFixed(2);
    },
    //一些操作需要更改购物车里信息的
    changeCar(item) {
      const newList = [...this.$store.state.sendTickCarList];
      const index = newList.findIndex((v) => v.id == item.id);
      if (index >= 0) {
        newList[index] = { ...item };
        // 更新 Vuex 数据
        this.$store.commit("setTickCarList", newList);
      }
    },
    stepChange(value, detail) {
      //selectType
      // console.log(
      //   "点击了计步器··" + value + "···id是····" + JSON.stringify(detail.name)
      // );
      //  console.log(detail.name.groupIndex + "`````" + detail.name.index2);

      const newList = [...this.$store.state.sendTickCarList];
      //   console.log("值···" + value);
      if (value == "0" || value < 0) {
        //是0就删除，不是0就数
        //    console.log("是0了··删除去");
        newList[detail.name.groupIndex].carListbyUser.splice(
          detail.name.index2,
          1
        );
      } else {
        //  console.log("不是0改数吧");
        newList[detail.name.groupIndex].carListbyUser[
          detail.name.index2
        ].shenqingCount = value;
        //折扣申请的时候数量会影响总价格，需要把总价格改一下selectType==4
        if (this.selectType == "4") {
          let totalPriceTem =
            value *
            newList[detail.name.groupIndex].carListbyUser[detail.name.index2]
              .discountedPrice;
          // console.log(
          //   "```````" +
          //     newList[detail.name.groupIndex].carListbyUser[detail.name.index2]
          //       .discountedPrice +
          //     "````" +
          //     parseFloat(
          //       newList[detail.name.groupIndex].carListbyUser[
          //         detail.name.index2
          //       ].discountedPrice
          //     ).toFixed(2)
          // );
          newList[detail.name.groupIndex].carListbyUser[
            detail.name.index2
          ].discountedTotalPrice = totalPriceTem.toFixed(2);
        }
      }
      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
    },
    getUserDept() {
      getUserDepts(this.$store.state.loginUser.id)
        .then((rsp) => {
          this.userDepts = rsp.data;
          if (this.userDepts.length > 0) {
            this.userDeptId = this.userDepts[0].id;
          }
        })
        .catch((err) => {
          this.$err(err, "获取用户部门信息失败");
        });
    },
    addInfoBtn(index) {
      //this.$router.push("/selectTicket")
      this.$router.push({
        path: "/selectTicket",
        query: { modeNo: this.selectType, optionCarIndex: index },
      });
    },
    loadFormInfo(formId) {
      this.loading = true;
      getModelById(formId)
        .then((rsp) => {
          this.loading = false;
          console.log(rsp.data);
          let form = rsp.data;
          form.logo = JSON.parse(form.logo);
          this.form = form;
          //构建表单及校验规则
          this.$store.state.design = form;
          this.loadFormData();
        })
        .catch((err) => {
          this.loading = false;
          Toast.fail("获取流程模型失败");
        });
    },
    loadFormData() {
      let instanceId = this.$route.query.instanceId;
      if (this.$isNotEmpty(instanceId)) {
        getInstanceFormData(instanceId)
          .then((rsp) => {
            for (let key in this.formData) {
              this.formData[key] = rsp.data[key];
            }
          })
          .catch((e) => {
            Toast.fail("回显数据失败");
          });
      }
    },
    addUser() {
      ///购物车添加组，一个用户一个组
      let tepCarList = [...this.$store.state.sendTickCarList];
      tepCarList.push({
        userName: "",
        userPhone: "",
        carListbyUser: [],
      });
      this.$store.commit("setTickCarList", tepCarList);
      // let carList=[
      //     {
      //       userName:"",
      //       userPhone:"",
      //       carListbyUser:[]
      //     }
      //   ]
    },
    submit() {
      if (!this.$isNotEmpty(this.form.processDefId)) {
        Toast.fail("流程未发布");
        return;
      }
      //
      this.validate((valid) => {
        if (valid) {
          // Toast.success('校验成功')
          //改下购物车结构
          localStorage.removeItem("newList");
          let tmpCarList = [...this.$store.state.sendTickCarList];
          // let newList = []; //改结构之后的list
          //到这里每一条都应该已经有用户名手机号了

          // tmpCarList.forEach((item) => {
          //   newList = [newList, ...item.carListbyUser];
          // });

          //统计出来一个大的列表
          let bigList = [];
          tmpCarList.forEach((item) => {
            bigList = [...bigList, ...item.carListbyUser];
          });
          // console.log("```" + JSON.stringify(bigList));
          if (bigList.length < 1) {
            Toast.fail("明细列表不能为空");
            return;
          }
          ////////////////
          //销售礼券申请&免费礼券申请:电子券需要校验填客人手机号才能提交申请
          if (this.selectType == "2" || this.selectType == "3") {
            // console.log("有名吗");
            let notUserList = bigList.filter((item) => {
              return (
                item.attributeType == 2 && item.userPhone == ""
                // (item.userName == "" || item.userPhone == "")
              );
            });
            // console.log(notUserList);
            if (notUserList.length > 0) {
              //Toast.fail("电子券的客人姓名、手机号都不能为空");
              Toast.fail("电子券的客人手机号不能为空");
              return;
            }

            // console.log(
            //   "免费礼券或者销售礼券的没有用户的" +
            //     notUserList.length +
            //     "````" +
            //     notUserList
            // );
          }

          //销售礼券价格不能空
          if (this.selectType == "2") {
            //  console.log("有名吗");
            let noPriceList = bigList.filter(
              (item) => !item.sellPrice || item.sellPrice < 0
            );
            // console.log(notUserList);
            if (noPriceList.length > 0) {
              //Toast.fail("电子券的客人姓名、手机号都不能为空");
              Toast.fail("价格不能为空且大于等于0");
              return;
            }
          }
          //延期申请  延期天数不能空
          if (this.selectType == "5") {
            //  console.log("有名吗");
            let noPriceList = bigList.filter(
              (item) => !item.days || item.days == ""
            );
            // console.log(notUserList);
            if (noPriceList.length > 0) {
              //Toast.fail("电子券的客人姓名、手机号都不能为空");
              Toast.fail("延期天数至少是1");
              return;
            }
          }
          let startParams = {
            deptId: this.userDeptId,
            formData: { ...this.formData, fieldlistM: bigList },
            processUsers: this.processUsers,
          };

          // localStorage.setItem("newList", JSON.stringify(tmpCarList));
          //localStorage.setItem("startParams", JSON.stringify(startParams));

          this.submitLoading = true;
          startProcess(this.form.processDefId, startParams)
            .then((rsp) => {
              Toast.success("提交成功");
              this.submitLoading = false;
              //清空购物车
              this.clearAll();

              this.$router.replace("/workspace/submit");
            })
            .catch((err) => {
              this.submitLoading = false;
              Toast.fail("提交失败");
            });
        } else {
          Toast.fail("请完善表单");
        }
      });
    },
    back() {
      this.$router.push("/workspace/forms");
    },
    validate(call) {
      this.$refs.form.validate((validForm) => {
        this.$refs.process.validate((validProcess) => {
          call(validForm, validProcess);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title-type {
  font-size: 1.5rem;
  color: #333;
  margin-top: 1rem;
}
.add-requet-page {
  /deep/ .van-nav-bar__text {
    color: #006c50 !important;
  }
  /deep/ .van-nav-bar__text {
    color: #006c50;
  }
  /deep/ .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #006c50 !important;
    border-color: #006c50 !important;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #006c50 !important;
  }
  .popup-sku-con-outer {
    padding: 1rem;
    .pop-title-con {
      margin: 1rem 0;
      font-size: #333;
      font-size: 1.4rem;
    }
  }
  .delay-outer {
    padding: 2rem;
    .pop-btns {
      margin-top: 10rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      /deep/ .van-button {
        height: 4rem;
        line-height: 4rem;
      }
      .count {
        display: flex;
        align-items: center;
      }
      .num {
        font-size: 2.4rem;
        color: #006c50;
        padding: 0 1rem;
      }
    }
    .pop-btns-edit-user {
      display: flex;
      justify-content: center;
      /deep/ .van-button {
        height: 4rem;
        line-height: 4rem;
        width: 80vw;
        background-color: #006c50;
      }
    }
  }
  .input-outer {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    .input-pop-title {
      font-size: #333;
      font-size: 1.3rem;
    }
    .edit-input {
      flex: 1;
      display: flex;
      align-items: center;
      /deep/ .el-input {
        font-size: 1.3rem;
        margin-left: 0.5rem;
        width: 90%;
        border: none;
        border-bottom: 1px solid #ccc;
      }
      /deep/ .el-input__inner {
        height: 3rem;
        border: none;
      }
      /deep/ .el-input__suffix {
        height: 3rem;
        line-height: 3rem;
        display: flex;
        align-items: center;
      }
      /deep/ .el-textarea {
        font-size: 1.3rem;
      }
    }
  }
  padding: 1rem;
  .info-outer {
    margin: 1rem 0;
    .user-group-head {
      margin-top: 2rem;
      position: relative;
      .user-title {
        flex: 1;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        color: #fff;
        padding: 1rem 0.5rem;
        background: linear-gradient(to right, #42ac75, #057451);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding-right: 4rem;
        .user-info {
          margin: 0 0.5rem;
          flex: 1;
          word-break: break-all;
        }
        .not-user-err {
          // color: red;
        }
      }
      .delet-user {
        position: absolute;
        top: -0.3rem;
        right: 0;
        padding: 0.5rem;
      }
    }

    .info-title-outer {
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      color: #333;
      .clear {
        color: #006c50;
      }
    }
    .add-info-btn {
      margin: 1rem auto;
      width: 100%;
      padding: 0.7rem 0;
      font-size: 1.2rem;
      color: #006c50;
      background-color: #fff;
      border: 1px dashed #006c50;
      border-radius: 0.8rem;
      text-align: center;
    }
  }

  .con-item {
    position: relative;
    padding: 2rem 0.8rem 1rem;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;
    .type {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0.6rem;
      color: #006c50;
      height: 2rem;
      line-height: 2rem;
      font-size: 1.3rem;
      padding: 0 0.8rem;
      background: #eef5f3;
    }
    .item-title {
      color: #333;
      font-size: 1.5rem;
      word-wrap: break-word;
      white-space: normal;
      font-weight: 600;
      padding-bottom: 0.7rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .item-line {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      color: #666666;
      align-items: center;
      margin-bottom: 0.5rem;
      .one {
        flex: 3;
      }
      .two {
        flex: 2.1;
      }
      .three {
        text-align: right;
        flex: 2;
      }
    }
    .item-line-between {
      font-size: 1.3rem;
      color: #666666;
      display: flex;
      margin-bottom: 0.7rem;
      justify-content: space-between;
    }
    .item-line-con {
      font-size: 1.3rem;
      color: #666666;
      margin-bottom: 0.7rem;
    }
    .item-line-option {
      font-size: 1.2rem;
      color: #666666;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .edit-price {
        .dis-price {
          font-size: 1rem;
        }
        .dj-price {
          margin: 0 0.5rem;
        }
        .price-err {
          color: red;
        }
        .date-edit-delay {
          display: inline-block;
          width: 12rem;
          margin-right: 0.5rem;
          border-bottom: 0.1rem solid #ccc;
        }
      }
      /deep/ .el-input {
        font-size: 1.2rem;

        width: 7rem;
        border: none;
        border-bottom: 0.1rem solid #ccc;
      }
      /deep/ .el-input__inner {
        height: 2rem;
        border: none;
        padding: 0 0.5rem;
      }
      /deep/ .el-input__suffix {
        height: 2rem;
        line-height: 2rem;
        display: flex;
        align-items: center;
      }
      // /deep/ .el-input__suffix{
      //   height: 2rem;
      //    border: none;
      //    line-height: 2rem;

      // }
      /deep/ .van-field {
        padding: 0;
        width: 10rem;
        font-size: 1.2rem;
      }
      .price {
      }
    }
    .day-err {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      color: red;
      font-size: 1.1rem;
    }
    .more-option {
      /deep/ .el-input {
        font-size: 1.2rem;

        width: 12rem;
        border: none;
        border-bottom: 0.1rem solid #ccc;
      }
    }
    .delay-detail-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      margin-bottom: 0.7rem;
      color: #666;
      .d-line-item {
        flex: 1;
        .title {
          color: #999;
        }
        .txt {
        }
      }
      .flex-max {
        flex: 1.5;
      }
    }
  }
}
.process-form {
  /deep/ .el-form-item__label {
    padding: 0 0;
  }
}

/deep/ .van-tabs {
  .van-tabs__wrap {
    border-bottom: 1px solid @theme-aside-bgc;
  }
  .van-tabs__line {
    background-color: @theme-primary;
  }
  .van-tab--active .van-tab__text {
    color: @theme-primary;
  }
}
/deep/ .van-button--normal {
  font-size: 1.6rem;
  width: 90vw;
  height: 4rem;
  padding: 1rem 0;
}

.btns {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem 1rem;
  background-color: #fff;
  display: flex;
  /deep/ .van-button {
    width: 40vw;
  }
  .add-btn {
    margin-right: 2rem;
  }
  .submit-btn {
    flex: 1;
  }
}
/*/deep/ .van-nav-bar--fixed{
  position: fixed !important;
}

/deep/ .van-nav-bar__content{
  background: @theme-primary;
  i, .van-nav-bar__text, .van-nav-bar__title{
    color: white;
  }
}*/
</style>
